import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import Customer from 'src/smoothr-web-app-core/models/Customer';
import { SelectLanguagePopoverComponent } from '../select-language-popover/select-language-popover.component';
import Venue from 'src/smoothr-web-app-core/models/Venue';
import Order from 'src/smoothr-web-app-core/models/Order';
import moment from 'moment';
import { NavigationService } from 'src/app/services/navigation.service';
import { OrderUtils } from 'src/smoothr-web-app-core/utils/order-utils';
import { numberToCurrency } from 'src/smoothr-web-app-core/utils/utils';

@Component({
	selector: 'app-app-header-menu',
	templateUrl: './app-header-menu.component.html',
	styleUrls: ['./app-header-menu.component.scss'],
})
export class AppHeaderMenuComponent {
	@Input()
	isBack: boolean = false;
	@Input()
	title: string = '';
	@Input()
	src: string;
	@Output() back = new EventEmitter<void>();
	@Input() customer: Customer;
	@Input() venue: Venue;
	@Input() order: Order;
	@Input() showGoToBasketBtn = false;
	@Output() showBasket = new EventEmitter<void>();
	public isOpen = false;
	public todayDate = moment();
	public listCountries = [
		{
			name: 'German',
			icon: '../../../assets/dean_and_david/lang/de.svg',
			value: 'de',
		},
		{
			name: 'English',
			icon: '../../../assets/dean_and_david/lang/gb.svg',
			value: 'en',
		},

		{
			name: 'Italy',
			icon: '../../../assets/dean_and_david/lang/cs.svg',
			value: 'cr',
		},
	];
	constructor(
		private popover: PopoverController,
		public translate: TranslateService,
		private navService: NavigationService
	) {}

	ngOnInit() {}
	goBack() {
		this.back.emit();
	}
	get price(): number {
		if (!this.order) {
			return 0;
		}
		const totalPrice = OrderUtils.articleGroupsTotalPrice(
			this.order.orderedArticles,
			this.order.type,
			this.order.preorder.type,
			this.order.terminalorder ? this.order.terminalorder.type : null,
			this.customer?.dndCompanyObject ?? null
		);
		if (!this.customer?.isAnonymous && this.customer?.dndCompany && this.customer?.dndCompanyObject?.storeDiscount) {
		}
		return totalPrice;
	}

	get formattedPrice(): string {
		return numberToCurrency(this.price, this.order.currency);
	}
	async openPopoverSelectionLanguage(ev: any) {
		if (this.isOpen) {
			return;
		}
		this.isOpen = true;
		const popover = await this.popover.create({
			component: SelectLanguagePopoverComponent,
			event: ev,
			translucent: true,
			cssClass: 'popover-select-language',
			showBackdrop: false,
			componentProps: {
				lang: this.translate.currentLang,
			},
		});
		popover.onWillDismiss().then(v => {
			// this.visible = false;
			this.isOpen = false;
		});
		await popover.present();
	}
	findFlag() {
		return this.listCountries.find(opt => opt.value === this.translate.currentLang).icon;
	}
	navToLogin() {
		this.navService.signIn();
	}
	myPorfile() {
		this.navService.account();
	}
}
