import { HomePage } from './../home/home.page';
import { Component, OnInit, ViewChild } from '@angular/core';
import { RepositoryService } from '../../../smoothr-web-app-core/services/repository/repository.service';
import ArticleCategory from '../../../smoothr-web-app-core/models/ArticleCategory';
import { ActivatedRoute, Router } from '@angular/router';
import { ToOrderButtonComponent } from '../../components/to-order-button/to-order-button.component';
import { AlertController, IonSearchbar, ModalController, PopoverController } from '@ionic/angular';
import { ModalInfoComponent } from '../../components/modal-info/modal-info.component';
import Article from '../../../smoothr-web-app-core/models/Article';
import ArticleGroup from '../../../smoothr-web-app-core/models/ArticleGroup';
import { AppComponent } from '../../app.component';
import { PreorderType } from '../../../smoothr-web-app-core/enums/PreorderType';
import * as moment from 'moment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { OrderPage } from '../order/order.page';
import { TranslateService } from '@ngx-translate/core';
import { OrderUtils } from '../../../smoothr-web-app-core/utils/order-utils';
import { OrderType } from '../../../smoothr-web-app-core/enums/OrderType';
import RepositoryDirective from '../../../smoothr-web-app-core/directives/repository-directive';
import { environment } from '../../../environments/environment';
import {
	axiosErrorToMessage,
	defaultsToArticleOption,
	getAvailability,
	getCustomAvailability,
	sleep,
} from '../../../smoothr-web-app-core/utils/utils';
import { AnalyticsService } from '../../../smoothr-web-app-core/services/analytics/analytics.service';
import { NavigationService, NAVIGATIONURLS } from 'src/app/services/navigation.service';
import { SelectLanguagePopoverComponent } from 'src/app/components/select-language-popover/select-language-popover.component';
import { BehaviorSubject, debounceTime, pairwise } from 'rxjs';
import { TOGGLE_TAG } from 'src/app/components/menu-filters/menu-filters.component';
import { WorkingHoursModalComponent } from 'src/app/components/working-hours-modal/working-hours-modal.component';
import { OrderContentModalComponent } from 'src/app/components/order-content-modal/order-content-modal.component';
import { ChangeVenuePopoverComponent, LeaveVenue } from 'src/app/components/change-venue-popover/change-venue-popover.component';
import { FormControl } from '@angular/forms';
import { RecomendationArticleModalComponent } from 'src/app/components/recomendation-article-modal/recomendation-article-modal.component';

@Component({
	selector: 'app-menu',
	templateUrl: './menu.page.html',
	styleUrls: ['menu.page.scss'],
})
export class MenuPage extends RepositoryDirective implements OnInit {
	static url = 'menu';

	@ViewChild(ToOrderButtonComponent, { static: false }) toOrderButton;
	@ViewChild('articleContent', { static: false }) articleList;
	@ViewChild(IonSearchbar, { static: false }) searchBar;
	@ViewChild('categoryList', { static: false }) categoryList;
	@ViewChild('inputDataSlider', { static: false }) slider;
	time = moment();
	environment = environment;
	scrollEvents = true;
	loading = false;
	searchTerm = '';
	categories: ArticleCategory[] = [];
	moment = moment;
	PreorderType = PreorderType;
	searchBarOpen = false;
	compoundFilers: string[] = [];
	selectedToggleTag = TOGGLE_TAG.All;
	public isOpenBasket$ = new BehaviorSubject<boolean>(false);
	public showFilters = false;
	public categoryItemOffset = '';
	public isLargeScreen = AppComponent.largeScreen;
	public isOpenPopoverBack = false;
	public categoryItemOffsetLast = '';
	public selectedFiltersArray: string[] = [];
	get preorderType(): PreorderType {
		return this.order?.preorder?.type;
	}
	public searchForm = new FormControl('');
	slideOptions = {
		spaceBetween: 0,
		initialSlide: 0,
		speed: 400,
		allowTouchMove: false,
	};
	public previousValue = 0;
	public hideCategory: boolean = false;
	constructor(
		public translate: TranslateService,
		protected repository: RepositoryService,
		private modalCtrl: ModalController,
		private snackbarCtrl: MatSnackBar,
		private analytics: AnalyticsService,
		private navigationService: NavigationService,
		private popover: PopoverController,
		private router: Router
	) {
		super(repository);
	}
	public selectedCategoryIndex$ = new BehaviorSubject<number>(0);
	private _selectedCategoryIndex = 0;

	get selectedCategoryIndex(): number {
		return this._selectedCategoryIndex;
	}

	set selectedCategoryIndex(value) {
		if (this._selectedCategoryIndex === value) {
			return;
		}
		this._selectedCategoryIndex = value;
		this.scrollTo(value);
	}

	get selectedCategory(): ArticleCategory {
		return this.categories[this.selectedCategoryIndex];
	}

	get active(): boolean {
		return (
			this.order &&
			OrderUtils.articleGroupsTotalPrice(
				this.order.orderedArticles,
				this.order.type,
				this.order.preorder.type,
				this.order.terminalorder ? this.order.terminalorder.type : null
			) > 0
		);
	}

	get index(): number {
		return this.categories.findIndex(category => category._id === this.selectedCategory._id);
	}

	static async navigate(router: Router) {
		await router.navigateByUrl(MenuPage.url);
	}

	async ngOnInit() {
		await super.ngOnInit();
		switch (window.location.pathname) {
			case '/' + NAVIGATIONURLS.order():
				break;
			case '/' + NAVIGATIONURLS.paymentCancel():
				console.log(this.translate.instant('order.payment_cancel'));
				this.snackbarCtrl.open(this.translate.instant('order.payment_cancel'), null, {
					duration: 2000,
				});
				break;
			case '/' + NAVIGATIONURLS.paymentFail():
				console.log(this.translate.instant('order.payment_fail'));
				this.snackbarCtrl.open(this.translate.instant('order.payment_fail'), null, {
					duration: 5000,
				});
				break;
		}

		this.loading = true;
		this.repository.venue.emit(
			await this.repository.getVenue(this.order && this.order.venue ? this.order.venue : environment.customerGroup + '_master')
		);
		this.reloadCategories();
		this.loading = false;
		this.isOpenBasket$.subscribe(v => {
			console.log(v);
		});
		this.searchForm.valueChanges.pipe(debounceTime(600)).subscribe(v => {
			this.onSearchTermChanged(v);
		});
	}

	largeScreen(): boolean {
		return AppComponent.largeScreen;
	}

	async scrollTo(index: number) {
		if (this.categories.length - 1 < index || index < 0) {
			return;
		}
		this.scrollEvents = false;
		if (this.selectedCategoryIndex !== index) {
			this.selectedCategoryIndex = index;

			await this.scrollCategoryListTo(index);
			await sleep(100);
			await this.scrollArticleListTo(index);
		}
		this.scrollEvents = true;
	}

	async onScroll(event) {
		return;
		const newValue = event?.detail?.currentY;
		if (newValue > this.previousValue) {
			this.hideCategory = true;
		} else {
			this.hideCategory = false;
		}
		this.previousValue = event?.detail?.currentY;
		const categoryElements = [...this.articleList.el.children];
		const categoryIndex = categoryElements
			.map(el => el.offsetTop)
			.map((value, index, array) => {
				return (
					value <= event.detail.currentY &&
					((index < array.length - 1 && event.detail.currentY < array[index + 1]) || index === array.length - 1)
				);
			})
			.findIndex(value => value);
		let prevIndex = -1;

		if (this.selectedCategory) {
			prevIndex = this.categories.findIndex(category => category._id === this.selectedCategory._id);
		}
		if (prevIndex === categoryIndex) {
			return;
		}
		if (this.scrollEvents) {
			this.selectedCategoryIndex = categoryIndex;
			this.selectedCategoryIndex$.next(categoryIndex);
			await this.scrollCategoryListTo(categoryIndex);
		}
	}

	private async scrollCategoryListTo(index: number) {
		if (
			index < 0 ||
			!this.categoryList?.nativeElement?.children[index] ||
			(!this.categoryList?.nativeElement?.scrollTo && !this.categoryList?.nativeElement?.scrollLeft)
		) {
			return;
		}
		this.categoryList.nativeElement.scrollTo(this.categoryList.nativeElement.children[index].offsetLeft, 0);
		await sleep(100);
	}
	checkPageIfHaveGrid(articleGroup: ArticleGroup) {
		console.log(articleGroup.article.groups.filter(it => !!it.tags.find(tag => tag.identifier === 'half_pizza')));
		return (
			articleGroup.article.groups.length > 0 &&
			articleGroup.article.groups.filter(it => !!it.tags.find(tag => tag.identifier === 'half_pizza')).length > 0
		);
		// return articleGroup.article.tags.find(it => it.identifier == 'half_pizza');
	}
	async openModal(item: Article, showRec: boolean = true) {
		if (this.loading) {
			return;
		}

		const articleGroup = new ArticleGroup();
		articleGroup.article = item;
		articleGroup.groups.push(...defaultsToArticleOption(item, [], item.defaults, this.order?.type, this.order.preorder.type));
		articleGroup.quantity = 1;

		const modal = await this.modalCtrl.create({
			cssClass: AppComponent.largeScreen ? 'item-modal large-modal' : 'item-modal',
			component: ModalInfoComponent,
			componentProps: {
				articleGroup,
			},
			mode: 'ios',
			backdropDismiss: true,
		});
		await modal.present();
		const response = await modal.onDidDismiss();
		if (response.data && response.data.articleGroup) {
			OrderUtils.addToOrder(this.order, response.data.articleGroup, this.analytics);

			this.repository.onOrderChange(this.order);
			if (showRec) {
				const mainRecommendations = item.mainRecommendations;

				if (mainRecommendations.length > 0) {
					let categoryArticles = [].concat(...this.venue.articleCategories.map(it => it.articles)) as Article[];
					categoryArticles = categoryArticles.filter(article => article.visible && article.isActive);

					const categoryArticlesMap = new Map(categoryArticles.map(it => [it._id, it]));
					const recommendedArticles: Article[] = [];

					mainRecommendations.forEach(it => {
						if (categoryArticlesMap.has(it)) {
							recommendedArticles.push(categoryArticlesMap.get(it));
						}
					});
					let result;
					if (recommendedArticles.some(art => art.visible)) {
						result = await RecomendationArticleModalComponent.show(this.modalCtrl, recommendedArticles, this.order);
					}

					this.modalCtrl.dismiss();
					if (result?.article) {
						this.openModal(result.article, false);
					}
				}
			}
		}
	}
	onCustomer(): void {}
	onSearchTermChanged(event) {
		this.searchTerm = event;

		this.reloadCategories();
	}

	reloadCategories() {
		if (!this.venue) {
			this.categories = [];
			return;
		}
		const lowerSearchTerm = this.searchTerm && this.searchTerm !== '' ? this.searchTerm.toLocaleLowerCase() : null;
		this.categories = this.venue.articleCategories
			.filter(category => !category.hidden)
			.map(category => {
				const cat: ArticleCategory = JSON.parse(JSON.stringify(category));
				cat.articles = cat.articles.filter(article => {
					const available = getCustomAvailability(article, OrderType.PREORDER, this.preorderType, null, true, false, true);
					let searchItem = true;
					let searchOptionItem = false;
					if (lowerSearchTerm) {
						const keys = [
							article.name.de.toLocaleLowerCase(),
							article.name.en.toLocaleLowerCase(),
							cat.name.de.toLocaleLowerCase(),
							cat.name.en.toLocaleLowerCase(),
						];
						searchItem = keys.map(key => key.indexOf(lowerSearchTerm)).find(result => result >= 0) !== undefined;
					}
					article.groups.forEach(group => {
						group.articles.forEach(artGroup => {
							if (artGroup.visible) {
								if (!searchOptionItem) {
									const keys = [artGroup.name.de.toLocaleLowerCase(), artGroup.name.en.toLocaleLowerCase()];
									searchOptionItem =
										keys.map(key => key.indexOf(lowerSearchTerm)).find(result => result >= 0) !== undefined;
								}
							}
						});
					});

					if (article.name.de.includes('Black Rice Lachs Avocado Bowl')) {
						console.log(article);
					}
					return available && (searchItem || searchOptionItem);
				});
				if (this.selectedToggleTag !== TOGGLE_TAG.All) {
					cat.articles = cat.articles.filter(article => {
						if (this.selectedToggleTag === TOGGLE_TAG.Vegeterian) {
							return article.compound?.features?.vegetarian;
						} else if (this.selectedToggleTag === TOGGLE_TAG.Vegan) {
							return article.compound?.features?.vegan;
						} else if (this.selectedToggleTag === TOGGLE_TAG.Meat) {
							return (
								article.compound?.features?.beef || article.compound?.features?.chicken || article.compound?.features?.pork
							);
						} else if (this.selectedToggleTag === TOGGLE_TAG.Fish) {
							return article.compound?.features?.fish;
						} else if (this.selectedToggleTag === TOGGLE_TAG.Alcohol) {
							return !article.compound?.additives?.containsAlcohol;
						} else {
							return false;
						}
					});
				}

				if (this.selectedFiltersArray.length > 0) {
					cat.articles = cat.articles.filter(article => {
						let articleFilter = false;
						this.selectedFiltersArray.forEach(it => {
							if (article?.compound?.features && article.compound.features[it] && article.compound.features[it] == true) {
								articleFilter = true;
							}
						});
						return articleFilter;
					});
				}

				return cat;
			})
			.filter(category => category.articles.length > 0);
		if (this.categories.length == 0) {
			this.snackbarCtrl.open(this.translate.instant('not_found_articles'), null, {
				duration: 3000,
			});
		}
		return;
	}

	onVenue() {
		super.onVenue();
		if (!this.venue) {
			this.repository
				.getVenue(this.order && this.order.venue)
				.then(venue => {
					this.repository.venue.emit(venue);
				})
				.catch(_ => {
					this.reloadCategories();
					this.selectedCategoryIndex = 0;
				});
			return;
		}
		this.reloadCategories();
		this.selectedCategoryIndex = 0;
	}

	onVenueError(error) {
		super.onVenueError(error);
		this.snackbarCtrl.open(axiosErrorToMessage(this.translate, error));
		this.loading = false;
		this.snackbarCtrl.open(
			this.translate.instant('menu_page.venue_timed_out'),
			this.translate.instant('menu_page.venue_timed_out_action')
		);
	}

	async scrollArticleListTo(index: number, time: number = 0) {
		if (!this.articleList) {
			return;
		}
		await this.articleList.scrollToPoint(0, this.articleList?.el?.children[index]?.offsetTop + 10, time);
	}
	async goHome() {
		await this.repository.cleanRepository();
		// await this.navigationService.map();
		this.router.navigateByUrl('/map', {
			replaceUrl: true,
		});
	}
	async goMap() {
		await this.repository.cleanRepository();
		// await this.navigationService.map();
		this.router.navigateByUrl('/map', {
			replaceUrl: true,
		});
	}
	changeSearchbar() {
		this.searchBarOpen = !this.searchBarOpen;
	}

	clearInput() {
		this.searchTerm = '';
		this.searchBarOpen = !this.searchBarOpen;
		this.reloadCategories();
	}
	findFlag() {
		return '/assets/' + this.translate.currentLang + '.svg';
	}
	async showPopover(ev: any) {
		const popover = await this.popover.create({
			component: SelectLanguagePopoverComponent,
			event: ev,
			animated: true,
			showBackdrop: true,
		});
		return await popover.present();
	}

	toggleTagValue(value: TOGGLE_TAG) {
		this.selectedToggleTag = value;
		this.reloadCategories();
	}
	showFiltersFunc(value: boolean) {
		this.showFilters = value;
	}
	selectedFiltersCompound(value: string[]) {
		this.compoundFilers = value;
		this.reloadCategories();
	}
	nextSlide() {
		this.slider.nativeElement.swiper.slideNext();
	}
	findCategoryIndex(categories: ArticleCategory[]) {
		return categories.find(it => it?._id === this.selectedCategory?._id) ?? categories[0];
	}

	openVenueInfo() {
		WorkingHoursModalComponent.show(this.modalCtrl, this.venue, this.order.preorder.type);
	}

	async toggleBasket() {
		console.log(this.isOpenBasket$.value);
		// this.isOpenBasket$.next(!this.isOpenBasket$.value);
		await OrderContentModalComponent.presentModal(this.modalCtrl);
	}
	async showPopoverToBack(ev: any) {
		if (this.isOpenPopoverBack) {
			return;
		}
		this.isOpenPopoverBack = true;
		const popover = await this.popover.create({
			component: ChangeVenuePopoverComponent,
			event: ev,
			translucent: true,
			cssClass: 'popover-select-language',
			showBackdrop: false,
			componentProps: {
				lang: this.translate.currentLang,
			},
		});
		popover.onWillDismiss().then(v => {
			console.log(v);

			if (v?.data == LeaveVenue.NAVIGATE_BACK) {
				// this.back.emit();
				this.navigationService.map();
			}
			this.isOpenPopoverBack = false;
		});
		await popover.present();
	}
	selectedFilters(event: string[]) {
		this.showFilters = false;
		if (event.length > 0) {
			this.selectedFiltersArray = event;
		} else {
			this.selectedFiltersArray = [];
		}
		this.reloadCategories();
	}
	trackBy(index: number, item: Article) {
		return item._id;
	}
	trackByCategory(index: number, item: ArticleCategory) {
		return item._id;
	}
}
