import { Injectable } from '@angular/core';
import * as appboy from '@braze/web-sdk';

@Injectable({
	providedIn: 'root',
})
export class BrazeService {
	constructor() {
		appboy.initialize('22816f0a-b186-4e11-93ce-fa9cc523fa9e', {
			baseUrl: 'https://sdk.fra-02.braze.eu/',
			enableLogging: true, // optional, enable for debugging
		});
		appboy.automaticallyShowInAppMessages();

		// if you use Content Cards
		appboy.subscribeToContentCardsUpdates(function (cards) {
			// cards have been updated
			console.log(cards);
		});
		appboy.subscribeToInAppMessage(inAppMessage => {
			console.log(inAppMessage);
		});
		console.log(appboy.isPushSupported());
		// optionally set the current user's external ID before starting a new session
		// you can also call `changeUser` later in the session after the user logs in

		appboy.openSession();
	}
	public isPushPermissionGranted() {
		return appboy.isPushPermissionGranted();
	}
	public checkIsSupported() {
		return appboy.isPushSupported();
	}
	public logCustomEvent(eventName: string, properties: any = {}) {
		appboy.logCustomEvent(eventName, properties);
	}
	private handlePushClick(event: any) {
		console.log('Push notification clicked:', event);
		// You can route to a specific page, log an event, or perform other actions
	}
	setUser(userId: string) {
		appboy.changeUser(userId);
	}
	requestPushPermission() {
		appboy.subscribeToInAppMessage(inpp => {
			console.log(inpp);
		});
		appboy.requestPushPermission(
			(enpoint, publickey, userAuth) => {
				console.log('Push notification permission:', enpoint, publickey, userAuth);
			},
			denied => {
				console.error('Push notification permission error:', denied);
			}
		);
	}
	setCustomUserAttribute(attributeName: string, value: any) {
		appboy.getUser().setCustomUserAttribute(attributeName, value);
	}

	setMultipleCustomAttributes(attributes: { [key: string]: any }) {
		Object.keys(attributes).forEach(key => {
			appboy.getUser().setCustomUserAttribute(key, attributes[key]);
		});
	}
	showInAppMessage() {
		// appboy.registerAppboyPushMessages();
		// appboy.display.automaticallyShowNewInAppMessages();
		// appboy.subscribeToInAppMessage((inAppMessage) => {
		// 	console.log(inAppMessage)
		// })
		// window.addEventListener('braze.inAppMessageReceived', (event: any) => {
		// 	console.log('In-app message received:', event.detail);
		// });
	}
}
