import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { PreorderType } from '../../../smoothr-web-app-core/enums/PreorderType';
import moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { RepositoryService } from '../../../smoothr-web-app-core/services/repository/repository.service';
import { AlertController, ModalController } from '@ionic/angular';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import RepositoryInterface from '../../../smoothr-web-app-core/directives/repository-directive';
import { OrderListAction } from '../../enums/OrderListAction';
import { AppComponent } from '../../app.component';
import { ModalInfoComponent } from '../modal-info/modal-info.component';
import { CheckoutModalComponent } from '../checkout-modal/checkout-modal.component';
import { OrderUtils } from '../../../smoothr-web-app-core/utils/order-utils';
import Article from '../../../smoothr-web-app-core/models/Article';
import ArticleOption from '../../../smoothr-web-app-core/models/ArticleOption';
import { MenuPage } from '../../pages/menu/menu.page';
import Order from '../../../smoothr-web-app-core/models/Order';
import { PromoCodeType } from '../../../smoothr-web-app-core/models/PromoCodeType';
import {
	defaultsToArticleOption,
	getBasePrice,
	maxFskOfOrder,
	numberD,
	numberToCurrency,
	sleep,
} from '../../../smoothr-web-app-core/utils/utils';
import { AnalyticsService } from '../../../smoothr-web-app-core/services/analytics/analytics.service';
import { NoteArticleModalComponent } from '../note-article-modal/note-article-modal.component';
import { NavigationService } from 'src/app/services/navigation.service';
import ArticleGroup from 'src/smoothr-web-app-core/models/ArticleGroup';
import { SwiperOptions } from 'swiper/types';
import { OrderType } from 'src/smoothr-web-app-core/enums/OrderType';
import { debounceTime } from 'rxjs';

@Component({
	selector: 'app-order-content',
	templateUrl: './order-content.component.html',
	styleUrls: ['order-content.component.scss'],
})
export class OrderContentComponent extends RepositoryInterface implements OnInit {
	moment = moment;

	isValid = false;
	loading = false;

	ou = OrderUtils;
	pt = PreorderType;
	differenceToMvo: number;
	@Input()
	fromMenu = false;
	promoError: string;
	tip = 0;
	totalPriceValue = '';
	showAgeVefiried: boolean = false;
	ageVerified: boolean = false;
	recommendationArticles: Article[] = [];
	numberToCurrency = numberToCurrency;
	numberD = numberD;
	@Output() openCheckout = new EventEmitter<void>();
	@Output() closeOrderContent = new EventEmitter<void>();
	@ViewChild('swiperSlider') swiper: ElementRef | null;
	constructor(
		public translate: TranslateService,
		protected repository: RepositoryService,
		private modalCtrl: ModalController,
		private snackbarCtrl: MatSnackBar,
		private router: Router,
		private route: ActivatedRoute,
		private alertCtrl: AlertController,
		private analytics: AnalyticsService,
		private navigationService: NavigationService
	) {
		super(repository);
		this.validate();
	}

	get hasArticles(): boolean {
		return !!this.order && this.order.orderedArticles.length > 0;
	}

	ngOnInit() {
		super.ngOnInit();
		this.analytics.openOrderContent();
		this.repository.recommendationList$.pipe(debounceTime(500)).subscribe(recommendationArt => {
			if (this.venue && recommendationArt.length > 0) {
				this.recommendationArticles = [];
				recommendationArt = recommendationArt.map(it => {
					// it.articles = it.articles.splice(0, 2);
					return it;
				});
				const categoryArticles = [].concat(...this.venue.articleCategories.map(it => it.articles)) as Article[];
				const categoryArticlesMap = new Map(categoryArticles.map(it => [it._id, it]));
				recommendationArt.forEach(it => {
					it.articles.forEach(art => {
						if (categoryArticlesMap.has(art)) {
							this.recommendationArticles.push(categoryArticlesMap.get(art));
						}
					});
				});
				console.log(this.recommendationArticles);

				this.startAutoPlaySlider();
			} else {
				this.recommendationArticles = [];
			}
		});
	}
	async startAutoPlaySlider() {
		try {
			await sleep(1500);
			this.swiper.nativeElement?.swiper?.update;
			this.swiper.nativeElement?.swiper?.autoplay?.start();
			// console.log(this.swiper.nativeElement.swiper.update())
		} catch (e) {
			console.log(e, 'Problem with playing swiper...');
		}
	}
	onVenue() {
		super.onVenue();
		this.validate();
	}
	onCustomer(): void {
		super.onCustomer();
		if (this.order) {
			this.totalPrice();
		}
	}

	onOrder() {
		super.onOrder();
		if (this.order && this.order.orderedArticles && this.order.orderedArticles.length > 0) {
			this.analytics.orderHasProducts();
		}
		this.validate();
		if (this.order) {
			this.totalPrice();
		}
	}

	async onAction(index: number, event: OrderListAction) {
		switch (event) {
			case OrderListAction.add:
				this.order.orderedArticles[index].quantity += 1;
				break;
			case OrderListAction.delete:
				if (OrderUtils.isBogoOrFreeArticlePromo(this.order) && this.order.promoCode.type === PromoCodeType.BOGO) {
					const bogoArticleIndex = this.order.orderedArticles.findIndex(ag => ag.isPromo);
					const bogoArticle = this.order.orderedArticles[bogoArticleIndex];
					const matchingBogoArticleIndices = this.order.orderedArticles
						.map((ag, agIndex) => {
							return ag.article._id === bogoArticle.article._id &&
								!ag.isPromo &&
								OrderUtils.bogoPrice(ag, this.order.type, this.order.preorder.type) ===
									OrderUtils.bogoPrice(bogoArticle, this.order.type, this.order.preorder.type)
								? agIndex
								: -1;
						})
						.filter(agIndex => agIndex !== -1);
					if (matchingBogoArticleIndices.find(bogoIndex => bogoIndex === index) !== undefined) {
						// article that should be removed is a possible parent of promoCode article
						if (matchingBogoArticleIndices.length === 1) {
							// only that article is possible parent of promoCode. If this article is removed the PromoCode and PromoArticle
							// should also be removed. Ask user.
							const bogoAlert = await this.alertCtrl.create({
								header: this.translate.instant('remove_bogo_alert.header'),
								message: this.translate.instant('remove_bogo_alert.message'),
								buttons: [
									{
										text: this.translate.instant('remove_bogo_alert.button_remove'),
										handler: () => {
											this.order.orderedArticles[bogoArticleIndex].isPromo = false;
											this.order.promoCode = null;
											this.order.orderedArticles.splice(index, 1);
											bogoAlert.dismiss();
										},
									},
									{
										text: this.translate.instant('remove_bogo_alert.button_keep'),
										handler: () => {
											bogoAlert.dismiss();
										},
									},
								],
							});
							await bogoAlert.present();
							await bogoAlert.onDidDismiss();
							break;
						}
					}
				}
				this.order.orderedArticles.splice(index, 1);
				break;
			case OrderListAction.note:
				this.noteArticle(index);
				break;
			case OrderListAction.edit:
				const modal = await this.modalCtrl.create({
					cssClass: AppComponent.largeScreen ? 'item-modal large-modal' : 'item-modal',
					component: ModalInfoComponent,
					componentProps: {
						// copy article group
						articleGroup: JSON.parse(JSON.stringify(this.order.orderedArticles[index])),
						recommend: false,
					},
					showBackdrop: true,
					backdropDismiss: true,
				});
				await modal.present();
				const response = await modal.onDidDismiss();
				if (response.data && response.data.articleGroup) {
					const promoAgIndex = this.order.orderedArticles.findIndex(oa => oa.isPromo);
					if (
						OrderUtils.isBogoOrFreeArticlePromo(this.order) &&
						this.order.promoCode.type === PromoCodeType.BOGO &&
						this.order.orderedArticles[index].article._id === this.order.orderedArticles[promoAgIndex].article._id &&
						OrderUtils.bogoPrice(response.data.articleGroup, this.order.type, this.order.preorder.type) <
							OrderUtils.bogoPrice(this.order.orderedArticles[promoAgIndex], this.order.type, this.order.preorder.type)
					) {
						this.order.orderedArticles[promoAgIndex].isPromo = false;
						const newPromoAg = JSON.parse(JSON.stringify(response.data.articleGroup));
						newPromoAg.isPromo = true;
						this.order.orderedArticles.push(newPromoAg);
					}
					this.order.orderedArticles[index] = response.data.articleGroup;
					this.repository.onOrderChange(this.order);
				}
				break;
			case OrderListAction.remove:
				if (this.order.orderedArticles[index].quantity > 1) {
					this.order.orderedArticles[index].quantity -= 1;
				} else {
					await this.onAction(index, OrderListAction.delete);
					return;
				}
				break;
		}
		this.repository.onOrderChange(this.order);
	}

	async openCheckoutModal() {
		this.validate();
		if (!this.isValid || this.loading) {
			return;
		}
		this.loading = true;
		if (AppComponent.largeScreen) {
			this.openCheckout.emit();
		}
		const result = await CheckoutModalComponent.show(this.modalCtrl, this.analytics, this.order, this.tip);
		if (result.data === undefined) {
			this.loading = false;
			return;
		}
		this.order.orderAt = result.data.orderAt;
		this.order.preorder = result.data.preorder;
		this.tip = result.data.tip;
		this.repository.onOrderChange(this.order);

		try {
			await this.repository.createOrderWithPayment(
				this.modalCtrl,
				this.translate,
				this.order,
				this.tip,
				loading => (this.loading = loading),
				this.snackbarCtrl
			);
		} catch (e) {
			console.log(JSON.parse(JSON.stringify(e)));
		}
	}

	validate() {
		if (!this.order || !this.venue || !this.order.preorder) {
			this.isValid = false;
			return;
		}
		const result = OrderUtils.validateOrder(this.venue, this.order);
		this.differenceToMvo = result.movDifference;
		const orderFSK = maxFskOfOrder(this.order);

		if (orderFSK > 0) {
			this.showAgeVefiried = true;
			if (!this.ageVerified) {
				this.isValid = false;
				return;
			}
		} else {
			this.showAgeVefiried = false;
		}

		this.isValid = result.valid;
	}
	amountOfPoints() {
		return OrderUtils.orderTotalPrice(this.order, true, true) + this.tip;
	}
	mapGroup(articleGroup: ArticleGroup) {
		if (!this.venue || !this.order) {
			return articleGroup;
		}
		if (articleGroup.isHalfPizza) {
			const foundArticle = this.venue.articleCategories
				.map(it => {
					if (it.visible) {
						const foundArticle = it.articles.find(ar => ar._id === articleGroup.article._id);

						return foundArticle ?? null;
					} else {
						return null;
					}
				})
				.filter(it => !!it);
			if (foundArticle.length === 1) {
				const test = JSON.parse(JSON.stringify(articleGroup)) as ArticleGroup;
				test.article = JSON.parse(JSON.stringify(foundArticle[0]));
				return test;
			}
			return articleGroup;
		}
		return articleGroup;
	}
	totalPrice() {
		console.log(this.customer, this.customer?.dndCompanyObject);
		this.totalPriceValue = numberToCurrency(
			OrderUtils.orderTotalPrice(this.order, true, true, this.customer?.dndCompanyObject ?? null) + this.tip,
			this.order.currency
		);
	}

	replace(index: number, article: Article, options: ArticleOption[]) {
		this.order.orderedArticles[index].quantity = 1;
		this.order.orderedArticles[index].article = article;
		this.order.orderedArticles[index].groups = options;
	}

	onPromoApplied(order: Order) {
		this.promoError = null;
		this.repository.onOrderChange(order);
	}

	onDeletePromo(order: Order) {
		this.promoError = null;
		this.repository.onOrderChange(order);
	}

	onPromoCodeError(error: string) {
		if (!error) {
			// error just resetting error no error happened
			return;
		}
		// Promo error
		this.snackbarCtrl.open(error, null, { duration: 2000 });
		this.repository.onOrderChange(OrderUtils.removePromo(this.order));

		this.promoError = error;
	}

	async backToMenu() {
		if (AppComponent.largeScreen) {
			this.close();
		} else {
			await this.navigationService.menu();
		}
	}

	async noteArticle(index: number) {
		const modal = await this.modalCtrl.create({
			cssClass: 'short-info-modal auto-height',
			component: NoteArticleModalComponent,
			componentProps: {
				// copy article group
				articleGroup: JSON.parse(JSON.stringify(this.order.orderedArticles[index])),
				recommend: false,
			},
			showBackdrop: true,
			backdropDismiss: true,
		});
		await modal.present();
		const response = await modal.onDidDismiss();
		if (response?.data || response.data === '') {
			this.order.orderedArticles[index].note = response?.data;
		}
	}

	handleChangeRadio(event: { detail: { value: string } }) {}
	login() {
		this.navigationService.signIn();
	}
	signUp() {
		this.navigationService.signUp();
	}
	checkPageIfHaveGrid(articleGroup: ArticleGroup) {
		return (
			articleGroup.article.groups.length > 0 &&
			articleGroup.article.groups.filter(it => !!it.tags.find(tag => tag.identifier === 'half_pizza')).length > 0
		);
		// return articleGroup.article.tags.find(it => it.identifier == 'half_pizza');
	}
	identify(index, item) {
		return item?.group;
	}
	changeAgeVerification() {
		this.validate();
	}
	addArticle(article: Article) {}
	checkMeasurmentPlusPfand(article: Article) {
		let result = '';
		if (!this.order) {
			return;
		}
		const pfandValue = this.checkPfandTag(article);
		if (pfandValue) {
			result =
				this.translate.instant('pfand_text.zzgl') +
				(pfandValue === 'deposit15' ? numberToCurrency(0.15, this.order.currency) : numberToCurrency(0.25, this.order.currency)) +
				this.translate.instant('pfand_text.pfand') +
				'<br/>';
		} else {
			if (result.length > 0) {
				result = result + ')';
			}
		}
		if (article?.measurement && article?.measurement?.unit && article?.measurement?.amount) {
			result =
				result +
				' ' +
				article.measurement?.refAmount +
				' ' +
				article.measurement?.refUnit +
				' = ' +
				this.numberToCurrency(getBasePrice(article, OrderType.PREORDER, this.order.preorder.type), this.order.currency);
		}
		return result;
	}
	checkPfandTag(article: Article) {
		if (article.tags?.length === 0) {
			return '';
		}
		const deposit25 = article.tags.find(it => it.identifier === 'deposit25') ? 'deposit25' : '';
		const deposit15 = article.tags.find(it => it.identifier === 'deposit15') ? 'deposit15' : '';

		return deposit15 || deposit25;
	}
	close() {
		if (AppComponent.largeScreen) {
			this.closeOrderContent.emit();
		} else {
			this.navigationService.menu();
		}
	}
	async openModal(item: Article) {
		console.log(item);
		if (this.loading) {
			return;
		}

		const articleGroup = new ArticleGroup();
		articleGroup.article = item;
		articleGroup.groups.push(...defaultsToArticleOption(item, [], item.defaults, this.order?.type, this.order.preorder.type));
		articleGroup.quantity = 1;

		const modal = await this.modalCtrl.create({
			cssClass: AppComponent.largeScreen ? 'item-modal large-modal' : 'item-modal',
			component: ModalInfoComponent,
			componentProps: {
				articleGroup,
			},
			mode: 'ios',
			backdropDismiss: true,
		});
		await modal.present();
		const response = await modal.onDidDismiss();
		if (response.data && response.data.articleGroup) {
			OrderUtils.addToOrder(this.order, response.data.articleGroup, this.analytics);

			this.repository.onOrderChange(this.order);
		}
	}
}
