import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { AppComponent } from 'src/app/app.component';
import { OrderType } from 'src/smoothr-web-app-core/enums/OrderType';
import { PreorderType } from 'src/smoothr-web-app-core/enums/PreorderType';
import Article from 'src/smoothr-web-app-core/models/Article';
import ArticleGroup from 'src/smoothr-web-app-core/models/ArticleGroup';
import OptionGroup from 'src/smoothr-web-app-core/models/OptionGroup';
import Order from 'src/smoothr-web-app-core/models/Order';
import { OrderUtils } from 'src/smoothr-web-app-core/utils/order-utils';
import { defaultsToArticleOption, getBasePrice, numberD, numberToCurrency, sleep } from 'src/smoothr-web-app-core/utils/utils';
import { ValidationUtils } from 'src/smoothr-web-app-core/utils/validation-utils';

@Component({
	selector: 'app-recomendation-article-modal',
	templateUrl: './recomendation-article-modal.component.html',
	styleUrls: ['./recomendation-article-modal.component.scss'],
})
export class RecomendationArticleModalComponent implements OnInit {
	public articlesList: Article[] = [];
	public order: Order;
	numberToCurrency = numberToCurrency;
	constructor(
		private modalCtrl: ModalController,
		private translate: TranslateService
	) {}
	static async show(modalCtrl: ModalController, articlesList: Article[], order: Order): Promise<{ article: Article }> {
		const modal = await modalCtrl.create({
			component: RecomendationArticleModalComponent,
			cssClass: 'eight_height',
			componentProps: {
				articlesList,
				order,
			},
			showBackdrop: true,
		});
		await modal.present();
		await sleep(100);
		const result = (await modal.onDidDismiss()).data;
		return result;
	}
	ngOnInit() {}
	trackBy(index: number, article: Article) {
		return article._id;
	}
	private minOfItem<T>(items: T[], transformer: (item: T) => number): T {
		if (items.length === 1) {
			return items[0];
		}
		const values = items.map(item => transformer(item));
		const min = Math.min(...values);
		const index = values.indexOf(min);
		return items[index];
	}
	reloadPrice(articleValue: Article) {
		const articleGroup = new ArticleGroup();
		articleGroup.article = articleValue;
		articleGroup.groups = defaultsToArticleOption(articleValue, [], [], this.order.type, this.order.preorder.type);

		articleGroup.quantity = 1;

		const article = articleGroup.article;
		let price = OrderUtils.totalPrice(articleGroup, this.order?.type, this.order?.preorder?.type);

		const processedGroups: string[] = [];
		article.groups.forEach(grp => {
			if (processedGroups.indexOf(grp._id) >= 0) return;
			if (ValidationUtils.isGroupDependencyFulfilled(articleGroup.article, articleGroup.groups, grp).times < 0) return;
			if (grp.requiredAmount == grp.limit) {
				const minimumItem = this.minOfItem(grp.articles, opt => numberD(opt.price));

				price += numberD(minimumItem.price) * grp.requiredAmount;
				const dependants = this.dependenciesRegardingOptionArticle(article, article.groups, minimumItem);
				dependants.forEach(dependant => {
					const dependantMinimumItem = this.minOfItem(dependant.articles, opt => numberD(opt.price));
					price += grp.requiredAmount * dependant.requiredAmount * numberD(dependantMinimumItem.price);
					processedGroups.push(dependant._id);
				});
				processedGroups.push(grp._id);
			}
		});

		return numberToCurrency(price, this.order.currency);
	}
	dependenciesRegardingOptionArticle(article: Article, groups: OptionGroup[], optionArticle: Article): OptionGroup[] {
		return article.groupDependencies
			.map(gd => {
				if (gd.dependencies.find(dependeci => dependeci.groupArticles.includes(optionArticle._id)) == null) {
					return null;
				} else {
					return groups.find(it => gd.group === it._id);
				}
			})
			.filter(it => !!it);
	}

	dismiss() {
		this.modalCtrl.dismiss();
	}
	selectArticle(article: Article) {
		this.modalCtrl.dismiss({ article });
	}
	checkMeasurmentPlusPfand(article: Article) {
		let result = '';

		const pfandValue = this.checkPfandTag(article);
		if (pfandValue) {
			result =
				this.translate.instant('pfand_text.zzgl') +
				(pfandValue === 'deposit15' ? numberToCurrency(0.15, this.order.currency) : numberToCurrency(0.25, this.order.currency)) +
				this.translate.instant('pfand_text.pfand') +
				'<br/>';
		} else {
			if (result.length > 0) {
				result = result + ')';
			}
		}
		if (article?.measurement && article?.measurement?.unit && article?.measurement?.amount) {
			result =
				result +
				' ' +
				article.measurement?.refAmount +
				' ' +
				article.measurement?.refUnit +
				' = ' +
				this.numberToCurrency(getBasePrice(article, OrderType.PREORDER, this.order.preorder.type), this.order.currency);
		}
		return result;
	}
	checkPfandTag(article: Article) {
		if (article.tags?.length === 0) {
			return '';
		}
		const deposit25 = article.tags.find(it => it.identifier === 'deposit25') ? 'deposit25' : '';
		const deposit15 = article.tags.find(it => it.identifier === 'deposit15') ? 'deposit15' : '';

		return deposit15 || deposit25;
	}
	isFromPrice(article: Article) {
		// if (article._id === '66068f2303ce98995bd3527a') {
		// 	console.log(article);
		// }

		return !!article.tags.find(tag => tag.identifier === 'fromPrice');
	}
}
